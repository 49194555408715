import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import SectionTitle from "../SectionTitles/SectionTitle";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import GalleryData from '../../data/Gallery/gallery'
import SiteData from '../../data/site'
import {AppModal} from "../Modal";
import { useHistory } from "react-router-dom";

const Project = ({gallery}) => {
    return (
    <div className="project-hover"> 
        <h4>
            <Link to={`${process.env.PUBLIC_URL + `/gallery/${gallery.title.split(' ').join('-').toLowerCase()}?id=${gallery.id} `}`}>{gallery.title}</Link>
        </h4>
        
        <a rel="noreferrer" href={`${gallery.url}`} target='_blank' className="project-link"><p >Click to download</p><i className="icon_link"></i></a>
    </div>
    );
};

const GalleryContent  = ({classes}) => {
    return (
            <section className={`work-section ${classes?? ''}`} id="work">
                <div className="container">
                    <SectionTitle
                        title={SiteData.bioTitle}
					/>
                    <p className='base-header2'>{SiteData.bio}</p>
					<SectionTitle
						title="Free Printables" 
					/>
					{/* End: Heading */}
                    <div className="projects-list">  
                        <Tab.Container defaultActiveKey="all">
                            <Nav variant="pills" className="acount__nav justify-content-center nav nav-pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="all">all Materials</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="printables">Printables</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="jr">Muslim Jr Magazine</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="all">  

                                    { 
                                        GalleryData.map((gallery, i) => ( 
                                            <div className="col-md-3 col-sm-12" key={i}>
                                                <div className="single-project-item" style={{
                                                backgroundImage: `url(${(process.env.PUBLIC_URL + '/assets/images/' + gallery.thumb)})`
                                                }}>
                                                    <Project gallery={gallery}/>
                                                </div> 
                                            </div>
                                        ))
                                    } 
                                    
                                </Tab.Pane>
                                <Tab.Pane eventKey="printables">
                                    { 
                                    GalleryData.filter(el => el.tag.includes("printables"))?.map((gallery, i) => ( 
                                        <div className="col-md-3 col-sm-12" key={i}>
                                            <div className="single-project-item" style={{
                                            backgroundImage: `url(${( process.env.PUBLIC_URL + '/assets/images/' + gallery.thumb)})`
                                            }}>
                                                <Project gallery={gallery}/>
                                            </div> 
                                        </div>
                                    ))
                                    } 
                                </Tab.Pane>
                                <Tab.Pane eventKey="jr">
                                    { 
                                    GalleryData.filter(el => el.tag.includes("jr"))?.map((gallery, i) => ( 
                                        <div className="col-md-3 col-sm-12" key={i}>
                                            <div className="single-project-item" style={{
                                            backgroundImage: `url(${( process.env.PUBLIC_URL + '/assets/images/' + gallery.thumb)})`
                                            }}>
                                                <Project gallery={gallery}/>
                                            </div> 
                                        </div>
                                    ))
                                    } 
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>  
                </div>
            </section>

  );
};

const GatedContent = () => {
    const history = useHistory();
    const [isOpen, setIsOpen] = React.useState(true);
    const urlParams = new URL(window?.location?.toLocaleString()).searchParams;
    useEffect(() => {
        if(urlParams.get("success") === "1" && document?.cookie?.includes("success=1")){
          urlParams.delete("success");
          history.push("/")
          setIsOpen(false);
        }
      }, [urlParams, history])
    return( <>
        {!isOpen ?<GalleryContent /> : null }
        <AppModal isOpen={isOpen}/>
    </>)
}

export default GatedContent;


