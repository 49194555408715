import React from "react";
import { Link } from "react-router-dom"; 


const PageHeader = ({ title,bgImg, subtitle, social }) => {
  const styles = {
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    backgroundImage: `url(${bgImg})`
  }
  return ( 

    <header id="page-top" className="blog-banner" style={styles}>
        <div className="container" id="blog">
            <div className="row blog-header text-left">
                <div className="col-sm-12"> 
                  {/* <img className="header_logo" alt="social_icon" src={(process.env.PUBLIC_URL + '/logo.jpeg')}/> */}
                    <h3>{title}</h3>
                  {subtitle?  <p>{subtitle}</p> : null}
                      <p className="social"><img alt="social_icon" src={(process.env.PUBLIC_URL + '/assets/images/ig.png')}/>{social}</p>
                </div>
            </div>
        </div>  
    </header> 
    
  );
};
 
export default PageHeader;

 