import React, {useState } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "./form.css";
import CustomForm from './form';
import LayoutOne from '../../layouts/LayoutOne';
import PageHeader
 from '../PageHeader';
const Form = () => {

  const postUrl = `https://site.us21.list-manage.com/subscribe/post?u=9eb34b9d77ff4f0d9a8c5cc5b&id=0148e6c92a`;
  return (
    <LayoutOne>
			<PageHeader
				bgImg={(process.env.PUBLIC_URL + '/assets/images/page_bg_1.jpg')}
				title="Mona Mustafa"
        subtitle={""}
				social={"monamustafa89"}/>
          <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
              <CustomForm 
                status={status} 
                message={message} 
                onValidated={data => {
                  subscribe(data);
                }}/>
            )}/>
		</LayoutOne>);
};

export default Form;
