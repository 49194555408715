import React, { useEffect } from 'react';
import SubscribeForm from './SubscribeForm';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export function AppModal({isOpen}) {
  const customStyles = {
    content: {
      zIndex: 10000,
      height: '100%',
      width: '100%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      backgroundColor: "#f6f8fa"
    },
    overlay: {zIndex: 1000000}
  };

  return isOpen ?(
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onBackgroundClick={null}
      onEscapeKeydown={null}>
      <SubscribeForm />
    </Modal>
  
  ) : null;
}
