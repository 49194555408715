import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import SiteData from '../../data/site.json';
import SectionTitle from '../SectionTitles/SectionTitle';

const CustomForm = ({ status, message, onValidated }) => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if(status === "success"){
      document.cookie="success=1"
      history.push("/?success=1")
    }
  }, [status, history]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onValidated({
      'MERGE0':formData.email,
      'MERGE1':formData.name,
    });
  };

  return (
    <div className="form-container">
      <div className='container'>
        <SectionTitle title={SiteData.bioTitle} />
        <p className='base-header2'>{SiteData.bio}</p>
      </div>
      
      <h2>Subscribe</h2>
      <p>To get your free printables</p>
      <form className="form" onSubmit={(e) => handleSubmit(e)}>
        
      {status === "sending" && (
        <div className="mc__alert mc__alert--sending">
          sending...
        </div>
      )}
      {status === "error" && (
        <div 
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
        <p>(*) Indicates required</p>
        <div className="form-group">
          <input
            placeholder='Name*'
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            placeholder='Email*'
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <button className='' type="submit">Subscribe</button>
      </form>
    </div>
  );
};

export default CustomForm;